import React, { useEffect } from 'react';
import { HeadingStyle } from '../includes/heading-style';
import cx from 'classnames';
import styles from '../css/tabbed-table-data.module.css';
import {
  GIS_Array,
  GIS_Padder,
  StyleObject,
} from '../functions/global-instance-styles';
import { Tabs, TabList, TabPanel, TabPanels } from '@reach/tabs';
import {
  useURLParamState,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { slugify, HandleAnimations } from '../functions/helper';
import { MenuSection } from './includes/menu-section';
import { MenuTab } from './includes/menu-tab';

export interface HTMLMenuProps {
  header?: string;
  header_noTx?: string;
  menu?: string;
  tabTypeButtonStyle?: string;
  menuRepeater?: {
    tabTitle?: string;
    tabTitle_noTx?: string;
    menuSections?: {
      sectionBackgroundColour?: string;
      sectionDisclaimer?: boolean;
      sectionDisclaimerLine1?: string;
      sectionDisclaimerLine1_noTx?: string;
      sectionDisclaimerLine2?: string;
      sectionDisclaimerLine2_noTx?: string;
      sectionDisclaimerColour?: string;
      sectionAlignment?: string;
      sectionItems?: {
        itemDisclaimer?: string;
        itemDisclaimer_noTx?: string;
        itemLine1?: string;
        itemLine1_noTx?: string;
        itemLine2?: string;
        itemLine2_noTx?: string;
        itemPrice?: string;
        itemPrice_noTx?: string;
      }[];
      sectionLayout?: string;
      sectionSubtitle?: string;
      sectionSubtitle_noTx?: string;
      sectionTitle?: string;
      sectionTitle_noTx?: string;
    }[];
  }[];
  menuFromPosts?: any;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  urlQueryParamName: string;
}

export function HTMLMenu({
  header,
  header_noTx,
  menu,
  tabTypeButtonStyle,
  menuRepeater,
  menuFromPosts,
  globalStyles,
  instanceStyles,
  urlQueryParamName,
}: HTMLMenuProps) {
  const { val: activeIndex, changeParamValue: changeActiveIndex } =
    useURLParamState({
      urlQueryParamName: urlQueryParamName,
    });

  /**
   * REACH UI Now handles all of the tab logic so that we dont need to.
   */
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  let idx = 0;

  if (menu) {
    menuFromPosts?.HTMLMenu?.map((item, key) => {
      if (menu === item?.node?.id) {
        menuRepeater = item?.node?.htmlMenuContent?.menuRepeater;
      }
    });
  }
  // Count valid array members in each menuReater. To be valid, a sub array must have both menu section contents and a menu tab title
  // If there's only one valid section/panel, then the presentation changes to a single section (non-tabbed) format

  const menuRepeaterCount =
    menuRepeater !== null
      ? menuRepeater.filter((obj) => obj?.menuSections && obj?.tabTitle).length
      : 0;

  // Account for any menuRepeater entries that didn't meet necessary criteria to match index with active tab index.
  const indexShift =
    menuRepeater.length !== menuRepeaterCount
      ? menuRepeater.length - menuRepeaterCount
      : 0;

  if (activeIndex instanceof Array) {
    if (activeIndex.length > 0) {
      idx = parseInt(activeIndex[0]);
    }
  } else {
    idx = parseInt(activeIndex);
  }

  idx = isNaN(idx) ? 0 : idx;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  const htmlAnchorFrag = `html-menu-${slugify(header ?? 'no-title')}`;
  return (
    <div
      data-testid={menu ? 'menuFromPosts' : 'htmlMenuContainer'}
      id={htmlAnchorFrag}
      className={cx(
        'bg-bg-alt cp-htmlMenus',
        styles.cpTabbedStyles,
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'left center',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
    >
      <div
        ref={animations?.ref}
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.contentBackgroundSize || null,
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || 'left center',
          backgroundColor: inlineStyles?.contentBackgroundColor,
        }}
      >
        {header && (
          <HeadingStyle
            text={inlineStyles?.headingsNoTranslate ? header_noTx : header}
            anchorFragment={htmlAnchorFrag}
            type="h2"
            styledAs="h1"
            className={cx('pb-10 text-center text-inverse', animations?.one, {
              OneLinkNoTx: inlineStyles?.headingsNoTranslate,
            })}
            textColorInline={inlineStyles?.titleColor}
          />
        )}
        <div>
          {/* Tabbed Panel version */}
          {menuRepeaterCount > 1 && (
            <Tabs index={idx} onChange={changeActiveIndex}>
              <TabList
                className={cx(
                  'w-full border-b-2 border-text-disabled flex flex-wrap md:flex-nowrap justify-center',
                  animations?.two
                )}
                style={{
                  borderColor: tabTypeButtonStyle
                    ? 'transparent'
                    : inlineStyles?.htmlMenuTabUnderlineColour,
                }}
              >
                <div
                  className={cx(
                    'relative',
                    tabTypeButtonStyle
                      ? 'flex flex-wrap justify-center sm:block'
                      : ''
                  )}
                >
                  {menuRepeater?.map((item, key) => {
                    return item?.tabTitle && item?.menuSections ? (
                      <MenuTab
                        key={key}
                        index={key}
                        title={
                          inlineStyles?.tabTitleNoTx
                            ? item?.tabTitle_noTx
                            : item?.tabTitle
                        }
                        tabTypeButtonStyle={tabTypeButtonStyle}
                        textColor={inlineStyles?.htmlMenuTabTextColour}
                        activeTabAccentColour={
                          inlineStyles?.htmlMenuActiveTabUnderlineColour
                        }
                        activeTabTextColour={
                          inlineStyles?.htmlMenuActiveTabTextColour
                        }
                        indexShift={indexShift}
                      />
                    ) : (
                      ''
                    );
                  })}
                </div>
              </TabList>
              <TabPanels className={cx('w-full', animations?.three)}>
                {menuRepeater?.map((item, key) => {
                  return item?.tabTitle && item?.menuSections ? (
                    <TabPanel tabIndex={null} key={key}>
                      <div
                        data-testid="tab-panel"
                        className="w-full flex flex-col mt-12 space-y-2 md:space-y-4"
                      >
                        {item?.menuSections?.map((item, key) => {
                          return (
                            <MenuSection
                              key={key}
                              item={item}
                              inlineStyles={inlineStyles}
                            />
                          );
                        })}
                      </div>
                    </TabPanel>
                  ) : (
                    ''
                  );
                })}
              </TabPanels>
            </Tabs>
          )}
          {/* Single section version (only 1 menu section available) */}
          {menuRepeaterCount === 1 && (
            <div className={animations?.two}>
              {menuRepeater?.map((item, key) => {
                return item?.tabTitle && item?.menuSections ? (
                  <div key={key}>
                    <div>
                      <h3
                        className={cx(
                          'text-2xl md:text-3xl leading-none font-headline htmlMenuTitle pb-3 text-center text-inverse font-extrabold',
                          {
                            OneLinkNoTx: inlineStyles?.tabTitleNoTx,
                          }
                        )}
                      >
                        {inlineStyles?.tabTitleNoTx
                          ? item?.tabTitle_noTx
                          : item?.tabTitle}
                      </h3>
                    </div>
                    <div className="mt-8">
                      {item?.menuSections?.map((subItem, subKey) => {
                        return (
                          <MenuSection
                            key={subKey}
                            item={subItem}
                            inlineStyles={inlineStyles}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
