import { useState } from 'react';
import { AnchorLink } from './global/anchor-link';
import { GIS_merge, StyleObject } from './functions/global-instance-styles';
import cx from 'classnames';
import styles from './css/retail-flyout.module.css';
import { CloseIcon } from '@curated-property/icons';
import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { HandleWYSIWYGContent } from './functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';

export interface RetailFlyoutProps {
  openByDefault?: boolean;
  pagePosition?: string;
  buttonStyle?: string;
  copy?: string;
  title?: string;
  link?: {
    target?: string;
    title?: string;
    url?: string;
  };
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function RetailFlyout({
  openByDefault,
  pagePosition,
  buttonStyle,
  copy,
  title,
  link,
  instanceStyles,
  globalStyles,
}: RetailFlyoutProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles) as StyleObject;
  const [isToggled, setIsToggled] = useState(false);
  const [delayedToggle, setDelayedToggle] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [transitionDir, setTransitionDir] = useState(
    'opacity-0 -translate-x-96 block hidden'
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (pagePosition && pagePosition.substring(0, 5) === 'right') {
      setTransitionDir('opacity-0 translate-x-96 block hidden');
    }
  }, [pagePosition]);

  useEffect(() => {
    if (openByDefault) {
      setIsToggled(true);
      setDelayedToggle(true);
    }
  }, [openByDefault]);

  const handleClick = (trigger?: string) => {
    if (trigger === 'close') {
      const button = document.getElementById('flyout-toggle-button');
      button?.focus();
    }
    if (!disabled) {
      if (isToggled) {
        setDisabled(true);
        setTimeout(() => {
          setDelayedToggle(false);
          setDisabled(false);
          setTransitionDir(transitionDir + ' hidden');
        }, 200);
      } else {
        setDelayedToggle(!delayedToggle);
      }
      if (pagePosition && pagePosition.substring(0, 5) === 'right') {
        setTransitionDir('opacity-0 translate-x-96 block');
      } else {
        setTransitionDir('opacity-0 -translate-x-96 block');
      }

      setTimeout(() => {
        setIsToggled(!isToggled);
      }, 100);
    } else return null;
  };

  const ariaTitle = title?.replace(':', ',');

  return (
    <section
      data-element-id="retail-flyout"
      data-testid="retail-flyout"
      className={cx(
        inlineStyles?.showHide ? 'hidden' : 'max-sm:hidden',
        `cp-retailFlyout fixed flex items-stretch z-[49] ${
          pagePosition?.substring(0, 5) === 'right'
            ? 'right-0 justify-end rtl:left-auto'
            : 'left-0'
        } z-50 text-bg ${
          pagePosition?.substring(pagePosition.length - 1) === 'p'
            ? 'top-40'
            : pagePosition?.substring(pagePosition.length - 1) === 'r'
            ? 'top-1/3'
            : pagePosition?.substring(pagePosition.length - 1) === 'm'
            ? 'bottom-5'
            : 'bottom-5'
        }`
      )}
    >
      <button
        data-testid={isToggled === true ? 'flyoutIsOpen' : 'flyoutIsClosed'}
        id="flyout-toggle-button"
        aria-label={ariaTitle}
        aria-expanded={isToggled ? true : false}
        className={cx(
          `${
            pagePosition?.substring(0, 5) === 'right'
              ? 'order-last -mr-1 rtl:order-first'
              : 'rtl:order-last'
          } z-[49] shadow-lg w-7 md:w-12 p-4 flex justify-center items-center bg-primary hover:cursor-pointer`
        )}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage?.sourceUrl ?? '',
                inlineStyles?.componentBackgroundRepeat
              )}')`
            : '',
          backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat:
            inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.componentBackgroundPosition || 'left center',
        }}
        onClick={() => handleClick()}
      >
        <h3
          className={cx(
            styles.verticalText,
            'text-bg p-2 font-headline uppercase transform whitespace-nowrap',
            (title?.length ?? 0) > 30 ? 'md:text-lg' : 'md:text-xl'
          )}
          style={{
            color: inlineStyles?.titleColor,
          }}
        >
          {title}
        </h3>
      </button>
      <div
        data-testid="flyout-body"
        aria-hidden={isToggled ? false : true}
        className={cx(
          `${
            pagePosition?.substring(0, 5) === 'right'
              ? 'border-r border-bg border-opacity-20'
              : 'border-l border-bg border-opacity-20'
          } ${
            isToggled
              ? 'transform transition duration-300 ease-in-out opacity-100'
              : transitionDir
          } shadow-lg w-10/12 sm:w-96 bg-primary text-bg overflow-hidden flex flex-col pb-5 justify-between items-center border-opacity-20 transform transition duration-300 ease-in-out`
        )}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl ?? '',
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : '',
          backgroundColor:
            inlineStyles?.contentBackgroundColor ||
            inlineStyles?.componentBackgroundColor ||
            undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || 'left center',
        }}
      >
        <div
          className={cx(
            `${
              pagePosition?.substring(0, 5) === 'right'
                ? 'justify-start ml-2 rtl:justify-end'
                : 'justify-end mr-4 rtl:justify-start'
            } flex w-full mt-3`
          )}
          style={{
            color: inlineStyles?.textColor,
          }}
        >
          <button
            data-element-id="retail-flyout-close"
            aria-hidden={isToggled ? false : true}
            className={cx(
              `flex items-center justify-around text-xs sm:text-sm font-extrabold w-16 sm:w-20 hover:cursor-pointer z-30 ${
                delayedToggle ? '' : 'hidden'
              }`
            )}
            onClick={() => handleClick('close')}
          >
            {t('close')}
            <CloseIcon
              fillColor={inlineStyles?.textColor || '#FFFFFF'}
              className={
                pagePosition?.substring(0, 5) === 'right'
                  ? 'order-first rtl:order-last'
                  : 'rtl:order-first'
              }
            />
          </button>
        </div>
        <div
          className={cx(
            styles.flyoutContent,
            `${
              inlineStyles?.textAlignment
                ? `text-${inlineStyles?.textAlignment}`
                : 'text-center'
            } z-30 mb-2 px-5 mr-0.5 text-sm sm:text-base sm:px-12 mt-5 leading-5 md:leading-normal max-h-72 sm:max-h-80 overflow-auto`
          )}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
          }}
          style={{
            color: inlineStyles?.textColor,
          }}
        ></div>
        <div
          className={cx(`z-30 mt-0 ${delayedToggle ? '' : 'hidden'}`)}
          style={{
            color: inlineStyles?.textColor,
          }}
        >
          <AnchorLink
            url={link?.url}
            title={link?.title}
            target={link?.target}
            className={cx(
              `border-[${inlineStyles?.textColor}] btn btn-bg-outline py-0 px-10 md:py-[.5rem] md:px-[1.5rem]`
            )}
            buttonStyle={buttonStyle || ''}
          />
        </div>
      </div>
    </section>
  );
}
