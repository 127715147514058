import { useTranslation } from 'next-i18next';
import {
  ArrayElement,
  guestsPerRoom,
  GuestsState,
  mergeRooms,
  parseDate,
  removeCtyhocnPrefixFromRoomCode,
} from '../utils';
import cx from 'classnames';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { useRouter } from 'next/router';
import { Maybe } from '@dx-ui/gql-types';

export interface RoomsCTAProps {
  room: ArrayElement<ReturnType<typeof mergeRooms>>;
  hasPrices: boolean;
  rate: string;
  ctyhocn: string;
  arrivalDate: string;
  departureDate: Maybe<string>;
  guests?: GuestsState;
  className?: string;
  selectedRoom?: boolean;
  inlineStyles?: any;
  currency?: any;
  onClick?: () => void;
}

export function RoomsCTA({
  room,
  hasPrices,
  rate,
  guests,
  ctyhocn,
  arrivalDate,
  departureDate,
  className,
  inlineStyles,
  currency,
  onClick,
}: RoomsCTAProps) {
  const { t } = useTranslation();
  const ctaText = !hasPrices
    ? t('checkAvailability')
    : t('bookFrom', { price: rate });
  const { locale } = useRouter();
  const adultsCount = guestsPerRoom(guests?.adults);
  const kidsCount = guestsPerRoom(guests.kids, guests.kidsAges);
  const arrival = parseDate(arrivalDate);
  const departure = departureDate && parseDate(departureDate);
  const dates =
    arrival && departure
      ? {
          arrivalDate: arrival,
          departureDate: departure,
        }
      : undefined;

  const bookUrl = bookUriBuilder({
    baseUrl: 'https://www.hilton.com',
    locale: locale as any,
    page: 'rates',
    urlParams: {
      ctyhocn: room?.hotelCtyhocn || ctyhocn,
      dates,
      rates: {
        roomTypeCode: removeCtyhocnPrefixFromRoomCode(room?.roomTypeCode),
      },
      rooms: [{ adults: [...adultsCount], children: [...kidsCount] }],
    },
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(!hasPrices || room?.moreRatesFromRate) && (
        <a
          data-testid="bookBtn"
          aria-label={`${ctaText?.replace(
            currency?.currencyCode,
            currency?.description
          )} (${t('opensInNewWindow')})`}
          className={cx(
            'popunder-cta py-2 font-bold text-center btn btn-primary rounded-md',
            className
          )}
          style={{
            backgroundColor: inlineStyles?.activeBookCtaBackgroundColor
              ? inlineStyles?.activeBookCtaBackgroundColor
              : inlineStyles?.activeTileColor
              ? '#ffffff'
              : inlineStyles?.bookCtaBackgroundColor,
            color: inlineStyles?.activeBookCtaTextColor
              ? inlineStyles?.activeBookCtaTextColor
              : inlineStyles?.activeTileColor
              ? inlineStyles?.activeTileColor
              : inlineStyles?.bookCtaTextColor,
            borderColor: inlineStyles?.activeBookCtaBorderColor
              ? inlineStyles?.activeBookCtaBorderColor
              : inlineStyles?.bookCtaBorderColor,
          }}
          href={bookUrl}
          rel="noopener noreferrer"
          onClick={onClick}
          target="_blank"
          suppressHydrationWarning
        >
          {ctaText}
          <span className="sr-only">{` ${room?.roomTypeName}, `}</span>
        </a>
      )}
    </>
  );
}
