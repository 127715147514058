import { HeadingStyle } from '@curated-property/shared-components';
import cx from 'classnames';
import { MenuItemPrice } from './menu-item-price';

export interface MenuSectionProps {
  item?: any;
  inlineStyles?: any;
}

export function MenuSection({ item, inlineStyles }: MenuSectionProps) {
  const baseAlign = item?.sectionAlignment ?? 'center';
  const textAlignClass = `text-${baseAlign}`; //TAILWIND_HINT: text-center text-left text-right

  return (
    <div
      style={{
        backgroundColor: item?.sectionBackgroundColour,
        borderColor: item?.sectionBorderColour,
        textAlign: inlineStyles?.sectionAlignment || 'left',
      }}
      className={cx(
        `flex flex-col justify-center items-center pt-10 pb-5 border-2 border-transparent`
      )}
    >
      {item?.sectionTitle && (
        <HeadingStyle
          text={
            inlineStyles?.sectionTitleNoTx
              ? item?.sectionTitle_noTx
              : item?.sectionTitle
          }
          type="h3"
          styledAs="h3"
          className={cx(
            'htmlMenuTitle pb-3 text-center text-inverse !text-[34px]',
            {
              OneLinkNoTx: inlineStyles?.sectionTitleNoTx,
            },
            inlineStyles?.htmlMenuTitleWeight !== 'bold'
              ? 'font-normal'
              : 'font-extrabold'
          )}
          textColorInline={inlineStyles?.titleColor}
        />
      )}

      {item?.sectionSubtitle && (
        <HeadingStyle
          text={
            inlineStyles?.sectionSubtitleNoTx
              ? item?.sectionSubtitle_noTx
              : item?.sectionSubtitle
          }
          type="p"
          styledAs="p"
          className={cx(
            'htmlMenuSubtitle pb-10 text-center text-inverse text-lg',
            inlineStyles?.htmlMenuSubtitleWeight !== 'bold'
              ? 'font-normal'
              : 'font-extrabold',
            {
              OneLinkNoTx: inlineStyles?.sectionSubtitleNoTx,
            }
          )}
          textColorInline={inlineStyles?.subtitleColor}
        />
      )}
      <div
        className={cx(
          item?.sectionLayout === 'Two Columns'
            ? 'grid grid-cols-1 sm:grid-cols-2 w-full md:w-4/5'
            : 'md:w-[500px]'
        )}
      >
        {item?.sectionItems?.map((menuItem, key) => {
          return (
            <div
              key={key.toString()}
              className={cx('flex flex-col pb-10 px-10')}
            >
              <div
                data-ht
                ml-menu="item-title"
                style={{
                  color:
                    item?.itemTitleColour ||
                    inlineStyles?.htmlMenuItemLine1Colour,
                }}
                className={cx('text-[28px] relative', textAlignClass)}
              >
                <HeadingStyle
                  text={
                    inlineStyles?.itemLine1NoTx
                      ? menuItem?.itemLine1_noTx
                      : menuItem?.itemLine1
                  }
                  type="h4"
                  styledAs="p"
                  className={cx(
                    'htmlMenuLine1 text-[28px]',
                    inlineStyles?.htmlMenuItemLine1Weight !== 'bold'
                      ? 'font-normal'
                      : '!font-bold',
                    {
                      OneLinkNoTx: inlineStyles?.itemLine1NoTx,
                    }
                  )}
                />
              </div>
              <div
                data-html-menu="item-subtitle"
                style={{
                  color:
                    item?.itemDescriptionColour ??
                    inlineStyles?.htmlMenuItemLine2Colour,
                }}
                className={cx(
                  'htmlMenuLine2 pt-4 text-lg',
                  textAlignClass,
                  inlineStyles?.htmlMenuItemLine2Weight !== 'bold'
                    ? 'font-normal'
                    : 'font-bold',
                  {
                    OneLinkNoTx: inlineStyles?.itemLine2NoTx,
                  }
                )}
              >
                {inlineStyles?.itemLine2NoTx
                  ? menuItem?.itemLine2_noTx
                  : menuItem?.itemLine2}
              </div>
              {menuItem?.itemPrice || menuItem?.itemPrice_noTx ? (
                <MenuItemPrice
                  inlineStyles={inlineStyles}
                  textAlignClass={textAlignClass}
                  color={
                    item?.itemPriceColour ||
                    inlineStyles?.htmlMenuItemLine1Colour
                  }
                  price={
                    inlineStyles?.itemPriceNoTx
                      ? menuItem?.itemPrice_noTx
                      : menuItem?.itemPrice
                  }
                />
              ) : (
                ''
              )}

              {menuItem?.itemPrices?.length
                ? menuItem?.itemPrices?.map((itemPrice, priceKey) => {
                    return (
                      <MenuItemPrice
                        inlineStyles={inlineStyles}
                        textAlignClass={textAlignClass}
                        color={
                          item?.itemPriceColour ||
                          inlineStyles?.htmlMenuItemLine1Colour
                        }
                        key={priceKey.toString()}
                        price={
                          inlineStyles?.itemPriceNoTx
                            ? itemPrice?.itemPrice_noTx
                            : itemPrice?.itemPrice
                        }
                      />
                    );
                  })
                : ''}

              <div
                data-html-menu="disclaimer"
                style={{
                  color:
                    item?.itemDisclaimerColour ||
                    inlineStyles?.htmlMenuItemLine1Colour,
                }}
                className={cx(
                  'htmlMenuDisclaimer text-sm italic pt-4',
                  textAlignClass,
                  inlineStyles?.htmlMenuItemDisclaimerWeight !== 'bold'
                    ? 'font-normal'
                    : 'font-bold',
                  {
                    OneLinkNoTx: inlineStyles?.itemDisclaimerNoTx,
                  }
                )}
              >
                {inlineStyles?.itemDisclaimerNoTx
                  ? menuItem?.itemDisclaimer_noTx
                  : menuItem?.itemDisclaimer}
              </div>
            </div>
          );
        })}
      </div>
      {item?.sectionDisclaimer && (
        <>
          <div
            style={{
              color: inlineStyles?.htmlMenuItemLine1Colour,
            }}
            className={cx(
              item?.sectionLayout !== 'Two Column'
                ? 'px-10 w-full md:w-4/5'
                : 'md:w-[500px]'
            )}
          >
            <HeadingStyle
              text={
                inlineStyles?.sectionDisclaimerLine1_noTx
                  ? item?.sectionDisclaimerLine1_noTx
                  : item?.sectionDisclaimerLine1
              }
              type="h4"
              textColorInline={item?.sectionDisclaimerColour}
              className={cx(
                'text-2xl pb-5',
                textAlignClass,
                inlineStyles?.htmlMenuTitleWeight === 'bold'
                  ? 'font-bold'
                  : 'font-normal',
                {
                  OneLinkNoTx: inlineStyles?.sectionDisclaimerLine1NoTx,
                }
              )}
            />
          </div>
          <div
            style={{
              color:
                item?.sectionDisclaimerColour ||
                inlineStyles?.htmlMenuItemLine1Colour,
            }}
            className={cx(
              'text-sm',
              textAlignClass,
              inlineStyles?.htmlMenuItemDisclaimerWeight === 'bold'
                ? 'font-bold'
                : 'font-normal',
              {
                OneLinkNoTx: inlineStyles?.sectionDisclaimerLine2NoTx,
              },
              item?.sectionLayout !== 'Two Column'
                ? 'px-10 w-full md:w-4/5'
                : 'md:w-[500px]'
            )}
          >
            {inlineStyles?.sectionDisclaimerLine2NoTx
              ? item?.sectionDisclaimerLine2_noTx
              : item?.sectionDisclaimerLine2}
          </div>
        </>
      )}
    </div>
  );
}
