import { HeadingStyle } from './includes/heading-style';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import {
  GIS_merge,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import {
  CroppedImage,
  customLoader,
  WrappedSubtitle,
  appliedCloudinaryParams,
  ParallaxLayer,
} from '@curated-property/utils';
import cx from 'classnames';
import {
  HandleWYSIWYGContent,
  useMediaQuery,
  HandleAnimations,
} from './functions/helper';
import contentStyles from './css/contentStyle.module.css';
import { WordpressPageInfoQuery } from './generated/wp';

export function twoOneMediaCopyPropMapper(
  data: TwoOneMediaCopyProps & { twoOneMediaCopySettings?: StyleObject },
  global: NonNullable<
    NonNullable<
      WordpressPageInfoQuery['componentStyles']
    >['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    title: data.title,
    title_noTx: data.title_noTx,
    subtitle: data.subtitle,
    subtitle_noTx: data.subtitle_noTx,
    copy: data.copy,
    buttons: data.buttons,
    largeImage: data.largeImage,
    smallImage: data.smallImage,
    imageLargeCropHeight: data.imageLargeCropHeight,
    imageLargeCropWidth: data.imageLargeCropWidth,
    imageLargeEnableCropping: data.imageLargeEnableCropping,
    imageLargeCropType: data.imageLargeCropType,
    imageLargeXPosition: data.imageLargeXPosition,
    imageLargeYPosition: data.imageLargeYPosition,
    imageLargeXPositionAdvanced: data.imageLargeXPositionAdvanced,
    imageLargeYPositionAdvanced: data.imageLargeYPositionAdvanced,
    imageSmallCropHeight: data.imageSmallCropHeight,
    imageSmallCropWidth: data.imageSmallCropWidth,
    imageSmallEnableCropping: data.imageSmallEnableCropping,
    imageSmallCropType: data.imageSmallCropType,
    imageSmallXPosition: data.imageSmallXPosition,
    imageSmallYPosition: data.imageSmallYPosition,
    imageSmallXPositionAdvanced: data.imageSmallXPositionAdvanced,
    imageSmallYPositionAdvanced: data.imageSmallYPositionAdvanced,
    instanceStyles: data?.twoOneMediaCopySettings,
    globalStyles: global?.twoOneMediaCopySettings,
  };
}

interface ImgProps {
  sourceUrl?: string;
  altText?: string;
}

export interface TwoOneMediaCopyProps {
  title?: string;
  title_noTx?: string;
  subtitle?: string;
  subtitle_noTx?: string;
  copy?: string;
  buttons?: Array<ACFAnchorProps>;
  largeImage?: ImgProps;
  smallImage?: ImgProps;
  imageLargeCropHeight?: string;
  imageLargeCropWidth?: string;
  imageLargeEnableCropping?: boolean;
  imageLargeCropType?: string;
  imageLargeXPosition?: string;
  imageLargeYPosition?: string;
  imageLargeXPositionAdvanced?: string;
  imageLargeYPositionAdvanced?: string;
  imageSmallCropHeight?: string;
  imageSmallCropWidth?: string;
  imageSmallEnableCropping?: boolean;
  imageSmallCropType?: string;
  imageSmallXPosition?: string;
  imageSmallYPosition?: string;
  imageSmallXPositionAdvanced?: string;
  imageSmallYPositionAdvanced?: string;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function TwoOneMediaCopy({
  title,
  title_noTx,
  subtitle,
  subtitle_noTx,
  copy,
  buttons,
  largeImage,
  smallImage,
  imageLargeCropHeight,
  imageLargeCropWidth,
  imageLargeEnableCropping,
  imageLargeCropType,
  imageLargeXPosition,
  imageLargeYPosition,
  imageLargeXPositionAdvanced,
  imageLargeYPositionAdvanced,
  imageSmallCropHeight,
  imageSmallCropWidth,
  imageSmallEnableCropping,
  imageSmallCropType,
  imageSmallXPosition,
  imageSmallYPosition,
  imageSmallXPositionAdvanced,
  imageSmallYPositionAdvanced,
  instanceStyles,
  globalStyles,
}: TwoOneMediaCopyProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles) as StyleObject;
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  // horizontal layout direction
  const horzLayout =
    inlineStyles?.horizontalDirection === 'reverse'
      ? 'lg:flex-row-reverse'
      : '';
  const horzTranslate =
    inlineStyles?.horizontalDirection === 'reverse'
      ? 'lg:-translate-x-12'
      : 'lg:translate-x-12';
  const horzItemsAlignment =
    inlineStyles?.horizontalDirection === 'reverse'
      ? 'items-start'
      : 'items-end';
  // vertical layout direction
  const vertLayout =
    inlineStyles?.verticalDirection === 'reverse' ? 'lg:flex-col-reverse' : '';
  const vertTranslate =
    inlineStyles?.verticalDirection === 'reverse'
      ? 'lg:translate-y-8'
      : 'lg:-translate-y-8';
  const vertItemsAlignment =
    inlineStyles?.verticalDirection === 'reverse' ? 'items-start' : 'items-end';

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : inlineStyles?.horizontalDirection === 'reverse'
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
    delayFour: 'delay-700',
  });

  let parallaxSpeed = useMediaQuery(1024) ? 25 : 85;
  if (inlineStyles?.verticalDirection === 'reverse') {
    parallaxSpeed *= -1;
  }

  return (
    <section
      ref={animations?.ref}
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'top left',
      }}
      data-element-id="two-one-media-and-copy-wrapper"
    >
      <div
        className={cx(
          'container flex flex-col lg:flex-row',
          horzLayout,
          paddingStyles
        )}
      >
        <div
          className={cx(
            'flex flex-col w-full lg:w-5/12 order-2 lg:order-1',
            horzItemsAlignment,
            vertLayout
          )}
        >
          {/* copy block */}
          <div
            className={cx(
              'bg-bg-alt transform p-6 lg:px-16 lg:py-20 xl:px-24 xl:py-28',
              horzTranslate
            )}
            style={{
              backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
                ? `url('${appliedCloudinaryParams(
                    inlineStyles?.contentBackgroundImage?.sourceUrl,
                    inlineStyles?.contentBackgroundRepeat
                  )}')`
                : undefined,
              backgroundColor:
                inlineStyles?.contentBackgroundColor || undefined,
              textAlign: textAlignment,
              alignItems: inlineStyles?.textAlignment,
              backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
              backgroundRepeat:
                inlineStyles?.contentBackgroundRepeat || 'no-repeat',
              backgroundPosition:
                inlineStyles?.contentBackgroundPosition || 'left center',
            }}
          >
            <HeadingStyle
              text={inlineStyles?.headingsNoTranslate ? title_noTx : title}
              type="h2"
              className={cx('mb-4', animations?.one, {
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              })}
              textColorInline={inlineStyles?.titleColor}
            />
            {subtitle ? (
              <WrappedSubtitle>
                <HeadingStyle
                  text={
                    inlineStyles?.headingsNoTranslate ? subtitle_noTx : subtitle
                  }
                  type="p"
                  styledAs="h2"
                  className={cx('mb-4 -mt-4', animations?.two, {
                    OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                  })}
                  textColorInline={inlineStyles?.subtitleColor}
                />
              </WrappedSubtitle>
            ) : (
              false
            )}
            <div
              className={cx(
                'twoOneMediaCopy--copy text-lg',
                contentStyles.paragraphStyle,
                contentStyles.listStyle,
                animations?.two
              )}
              style={{ color: inlineStyles?.textColor }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
              }}
            ></div>
            {buttons ? (
              <div className={`mt-6 ${animations?.three}`}>
                {buttons?.map((link, key) => {
                  if (!link) {
                    return null;
                  }
                  return (
                    <AnchorLink
                      key={key}
                      url={link.link?.url || ''}
                      title={link.link?.title || ''}
                      target={link.link?.target || ''}
                      buttonStyle={link?.buttonStyle ?? 'primary-outline'}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
          {/* small image */}
          <div
            style={{
              backgroundColor: inlineStyles?.smallImageBorderColour || null,
            }}
            className={cx(
              `w-[calc(100%+3rem)] text-[0px] bg-bg transform p-${
                inlineStyles?.smallImageBorderWidth ?? 6
              } relative z-2 hidden lg:block`,
              horzTranslate,
              vertTranslate
            )}
            data-element-id="small-image-wrapper"
          >
            <CroppedImage
              className={cx('w-full', animations?.four)}
              loader={() => {
                return customLoader({
                  src: smallImage?.sourceUrl || '',
                  width: '647',
                  height: '455',
                  crop: imageSmallEnableCropping || false,
                  cropType: imageSmallCropType || '',
                  cropHeight: imageSmallCropHeight || '',
                  cropWidth: imageSmallCropWidth || '',
                  xPosition: imageSmallXPosition || '',
                  xPositionAdvanced: imageSmallXPositionAdvanced || '',
                  yPosition: imageSmallYPosition || '',
                  yPositionAdvanced: imageSmallYPositionAdvanced || '',
                });
              }}
              src={smallImage?.sourceUrl || ''}
              width={647}
              height={455}
              alt={smallImage?.altText}
            />
          </div>
        </div>
        {/* large image */}

        <div
          className={cx(
            'lg:flex lg:justify-start w-full lg:w-7/12 order-1 lg:order-2 relative',
            vertItemsAlignment
          )}
        >
          <ParallaxLayer
            hideAnimations={
              animations?.hideAnimations ||
              inlineStyles?.hideParallax === 'hide'
            }
            inView={animations?.inView}
            parallaxSpeed={parallaxSpeed}
          >
            <CroppedImage
              className="w-full relative z-1"
              loader={() => {
                return customLoader({
                  src: largeImage?.sourceUrl || '',
                  width: '840',
                  height: '760',
                  crop: imageLargeEnableCropping || false,
                  cropType: imageLargeCropType || '',
                  cropHeight: imageLargeCropHeight || '',
                  cropWidth: imageLargeCropWidth || '',
                  xPosition: imageLargeXPosition || '',
                  xPositionAdvanced: imageLargeXPositionAdvanced || '',
                  yPosition: imageLargeYPosition || '',
                  yPositionAdvanced: imageLargeYPositionAdvanced || '',
                });
              }}
              src={largeImage?.sourceUrl || ''}
              width={840}
              height={760}
              alt={largeImage?.altText}
              layout={!useMediaQuery(1023) ? 'intrinsic' : 'responsive'}
            />
          </ParallaxLayer>
        </div>
      </div>
    </section>
  );
}
