import { useState, useRef, useEffect, useContext } from 'react';
import {
  CURATED_4082_HOTEL_LIST,
  CroppedImage,
  SharedContext,
  WrappedSubtitle,
  customLoader,
  imageUrl,
  isReducedMotion,
  useInitializeConductrics,
  useIsClient,
} from '@curated-property/utils';
import { HandleAnimations, hexToRgb, useWindowSize } from '../functions/helper';
import cx from 'classnames';
import OscComposableSearchForm from '../osc-composable/osc-composable-search-form';
import { GIS_merge, GIS_Padder } from '../functions/global-instance-styles';
import { HeroImageSlider } from './image-slider';
import { HeroImageOverlay } from './overlay';
import { HeroImageLogo } from './logo';
import { TFunction } from 'i18next';
import {
  HeroImageProps,
  HeroImageFlexProps,
  IHeroStyles,
} from './hero-image.types';
import { AnchorLink } from '../global/anchor-link';
import { WordpressPageInfoQuery } from '../generated/wp';
import { Status, getConductricsSelection } from '@dx-ui/framework-conductrics';
import { Spacer } from '../spacer';

/* istanbul ignore next */
export function heroPropMapper(
  componentData: HeroImageFlexProps,
  globalData: NonNullable<
    NonNullable<
      WordpressPageInfoQuery['componentStyles']
    >['globalComponentSettings']
  >['globalComponentSettings'],
  t: TFunction,
  oscBookButtonStyle: string
) {
  return {
    imgSrc: componentData?.image?.sourceUrl || '',
    imgAlt: componentData?.image?.altText || '',
    imgPositioning: componentData?.imagePositioning || '',
    title: componentData?.title || '',
    subtitle: componentData?.subtitle || '',
    titleSize: componentData?.titleSize || 'Normal',
    subtitleSize: componentData?.subtitleSize || 'Normal',
    buttons: componentData.buttons,
    imageCarouselChoice: componentData?.imageCarouselChoice ?? false,
    imageCarousel: componentData?.imageCarousel || [],
    videoAutoplay: false,
    videoChoice: componentData?.videoChoice ?? false,
    videoMuteChoice: componentData?.videoMute ?? false,
    videoCtaPause: componentData?.videoCtaPause || t('pauseVideo'),
    videoCtaPlay: componentData?.videoCtaPlay || t('playVideo'),
    videoUrl: componentData?.videoUrl?.mediaItemUrl,
    videoQualityOverride: componentData?.videoQualityOverride,
    videoQualitySlider: componentData?.videoQualitySlider,
    logoSrc: componentData?.logoImage?.sourceUrl || '',
    logoSize: componentData?.logoImage?.mediaDetails,
    logoAlt: componentData?.logoImage?.altText || '',
    logoOverlayColour: componentData?.logoOverlayColour || '',
    logoOverlayOpacity: componentData?.logoOverlayOpacity,
    logoWidth: componentData?.logoWidth,
    logoHeight: componentData?.logoHeight,
    bookingWidgetChoice: componentData?.bookingWidgetChoice,
    bookingWidgetPosition: componentData?.bookingWidgetPosition,
    bookingWidgetMobile: componentData?.bookingWidgetMobile,
    instanceStyles: componentData?.mastheadComponentSettings || '',
    enableCropping: componentData?.enableCropping,
    cropType: componentData?.cropType || '',
    xPosition: componentData?.xPosition || '',
    xPositionAdvanced: componentData?.xPositionAdvanced || '',
    yPosition: componentData?.yPosition || '',
    yPositionAdvanced: componentData?.yPositionAdvanced || '',
    cropWidth: componentData?.cropWidth || '',
    cropHeight: componentData?.cropHeight || '',
    autoPosition: componentData?.autoPosition,
    mobileCroppingXPosition: componentData?.mobileCroppingXPosition || '',
    mobileCroppingXPositionAdvanced:
      componentData?.mobileCroppingXPositionAdvanced || '',
    mobileCroppingYPosition: componentData?.mobileCroppingYPosition || '',
    mobileCroppingYPositionAdvanced:
      componentData?.mobileCroppingYPositionAdvanced || '',
    mobileCroppingCropWidth: componentData?.mobileCroppingCropWidth || '',
    mobileCroppingCropHeight: componentData?.mobileCroppingCropHeight || '',
    mobileCroppingAutoPosition: componentData?.mobileCroppingAutoPosition,
    globalStyles: globalData?.mastheadComponentSettings || undefined,
    oscBookButtonStyle: oscBookButtonStyle || '',
  };
}

export function Hero({
  propertyHasAlert,
  componentInstance,
  imgSrc,
  imgAlt,
  imgPositioning,
  title,
  subtitle,
  titleSize,
  subtitleSize,
  buttons,
  globalStyles,
  instanceStyles,
  videoAutoplay,
  videoCtaPlay,
  videoCtaPause,
  videoChoice,
  videoMuteChoice,
  videoQualityOverride,
  videoQualitySlider,
  videoUrl,
  logoSrc,
  logoAlt,
  logoSize,
  logoOverlayColour,
  logoOverlayOpacity,
  logoHeight,
  logoWidth,
  enableCropping,
  cropType,
  xPosition,
  xPositionAdvanced,
  yPosition,
  yPositionAdvanced,
  cropWidth,
  cropHeight,
  autoPosition,
  mobileCroppingAutoPosition,
  mobileCroppingCropHeight,
  mobileCroppingCropWidth,
  mobileCroppingXPosition,
  mobileCroppingXPositionAdvanced,
  mobileCroppingYPosition,
  mobileCroppingYPositionAdvanced,
  bookingWidgetChoice,
  bookingWidgetPosition,
  bookingWidgetMobile,
  bookingWidgetConfig,
  pageDetails,
  imageCarouselChoice,
  imageCarousel,
  oscBookButtonStyle,
  isShopFormInHeader,
}: HeroImageProps) {
  const [videoActive, setVideoActive] = useState(videoAutoplay);
  const [videoPause, setVideoPause] = useState(videoAutoplay);
  const [videoMute, setVideoMute] = useState(false);
  const [videoSoundBeforePlay, setVideoSoundBeforePlay] = useState(false);
  const [isPropertyGroup, setIsPropertyGroup] = useState(false);
  const [headerHeight, setHeaderHeight] = useState('');
  const [slideIndex, setSlideIndex] = useState(0);
  const [navAnimationDir, setNavAnimationDir] = useState(true);
  const [nextTimeout, setNextTimeout] = useState(null);
  const [nextImage, setNextImage] = useState({
    image: imageCarousel?.[slideIndex],
    ind: slideIndex,
  });
  const vidRef = useRef(null);
  const inlineStyles = GIS_merge(globalStyles, instanceStyles) as IHeroStyles;
  const hideAnimations =
    inlineStyles?.hideAnimations !== 'show' || isReducedMotion;
  const animationDirection: string = inlineStyles?.animationDirection;
  const associatedHotels = bookingWidgetConfig?.associatedHotels;
  const windowSize = useWindowSize();
  if (imageCarouselChoice && imgSrc) {
    imgSrc = null;
  }
  useInitializeConductrics();
  const defaultPaddingSize = propertyHasAlert ? '164px' : '128px';

  const [conductricsData, setConductricsData] = useState({ items: [] });
  const [shouldHideOnRoomsPage, setShouldHideOnRoomsPage] = useState(false);
  const { pageInfo, ctyhocn } = useContext(SharedContext);
  useEffect(() => {
    const fetchConductics = async function () {
      if (CURATED_4082_HOTEL_LIST.includes(ctyhocn?.toLocaleLowerCase())) {
        await getConductricsSelection('a-NbTy9nRvW5eP', Status.OK).then(
          (res) => {
            setConductricsData({ items: res?.items });
          }
        );
      }
    };
    fetchConductics();
  }, []);

  useEffect(() => {
    setShouldHideOnRoomsPage(
      conductricsData?.items?.length > 0 &&
        conductricsData?.items[0]?.c === 'B' &&
        pageInfo?.slug == 'rooms'
    );
  }, [conductricsData]);

  useEffect(() => {
    const isSafariMobile =
      typeof navigator !== 'undefined' &&
      /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(navigator.userAgent);

    // @ts-expect-error - window.safari is not standard and is only in the safari browser
    if (typeof window !== 'undefined' && (window.safari || isSafariMobile)) {
      setTimeout(() => {
        if (vidRef.current && vidRef.current.src !== videoUrl) {
          vidRef.current.src = videoUrl;
        }
      }, 50);
    }
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setHeaderHeight(
        `${document?.getElementsByTagName('header')?.[0]?.offsetHeight}px`
      );
    }
  }, [headerHeight, windowSize]);

  useEffect(() => {
    vidRef.current && !videoPause && vidRef.current.pause();
    vidRef.current && videoPause && vidRef.current.play();

    // Store an indication that user has unmuted the video prior to first play
    !videoActive && videoMute && setVideoSoundBeforePlay(true);

    // If a video has been unmuted prior to being played, use store value to play it after its default mute on play.
    if (videoSoundBeforePlay) {
      if (vidRef && videoPause) {
        setTimeout(() => {
          const vid = document.getElementById(
            'mastheadVideo'
          ) as HTMLVideoElement;
          if (videoPause && vid) {
            setVideoMute(true);
            vid.muted = false;
            // Reset the sound before play value as it's no longer useful.
            setVideoSoundBeforePlay(false);
          }
        }, 100);
      }
    }
  }, [
    vidRef,
    videoPause,
    videoMute,
    videoPause,
    videoActive,
    videoSoundBeforePlay,
  ]);

  useEffect(() => {
    if (bookingWidgetConfig?.associatedHotels?.length > 0) {
      setIsPropertyGroup(true);
    }
  }, [bookingWidgetConfig?.associatedHotels?.length]);

  const componentPaddingCheck =
    inlineStyles?.paddingTop?.paddingTopDesktop ||
    inlineStyles?.paddingBottom?.paddingBottomDesktop ||
    inlineStyles?.paddingTop?.paddingTopTablet ||
    inlineStyles?.paddingBottom?.paddingBottomTablet ||
    inlineStyles?.paddingTop?.paddingTopMobile ||
    inlineStyles?.paddingBottom?.paddingBottomMobile
      ? true
      : false;

  const paddingStyles = componentPaddingCheck
    ? GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom)
    : null;

  let mastheadGradientStyles = '';
  switch (inlineStyles?.mastheadGradient) {
    case 'Bottom to top':
      mastheadGradientStyles =
        'from-text bg-gradient-to-t to-transparent top-auto';
      break;
    case 'Top to bottom':
      mastheadGradientStyles = 'from-text bg-gradient-to-b to-transparent';
      break;
    case 'None':
      mastheadGradientStyles = 'hidden';
      break;
    default:
      mastheadGradientStyles = 'from-text bg-gradient-to-b to-transparent';
  }

  bookingWidgetChoice =
    useIsClient() &&
    pageDetails?.isFrontPage &&
    bookingWidgetChoice &&
    !isShopFormInHeader &&
    (bookingWidgetConfig?.resEnabled ||
      bookingWidgetConfig?.associatedHotels?.length > 0);

  const isFullWidthOverlay = inlineStyles?.overlayFullWidth === 'full';
  const isTabletSize: boolean = useWindowSize()?.width > 768;
  const isDesktopSize: boolean = useWindowSize()?.width >= 1024;
  const isLargeDesktopSize: boolean = useWindowSize()?.width >= 1280;
  const headerDisplacement: number =
    !isFullWidthOverlay &&
    isLargeDesktopSize &&
    !inlineStyles?.mastheadSpacer &&
    !componentInstance
      ? 145
      : 0;

  // An image gallery control has been clicked, this will pause and hide video
  const showImageGallery = () => {
    if (videoActive || !videoPause || videoMute) {
      setVideoActive(false);
      setVideoPause(false);
      setVideoMute(false);
    }
  };

  const hasBothControls = videoChoice && imageCarouselChoice && imageCarousel;

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
  });

  const opacity =
    ((isTabletSize && inlineStyles?.overlayBackgroundOpacity) || 100) / 100;
  const bgRGB = hexToRgb(inlineStyles?.overlayBackgroundColour);
  const overlayBg = bgRGB
    ? `rgba(${bgRGB?.r},${bgRGB?.g},${bgRGB?.b},${opacity})`
    : '';
  const bgRGBStyles =
    (!isTabletSize && inlineStyles?.overlayMobileBackgroundColour) ||
    overlayBg ||
    `rgba(38,38,38,${opacity})`;

  let titleSizeStyles,
    subtitleSizeStyles = '';
  switch (titleSize) {
    case 'Large':
      titleSizeStyles = 'text-4xl';
      break;
    case 'Normal':
      titleSizeStyles = 'text-2xl';
      break;
    default:
      titleSizeStyles = 'text-xl';
  }
  switch (subtitleSize) {
    case 'Large':
      subtitleSizeStyles = 'text-2xl';
      break;
    case 'Normal':
      subtitleSizeStyles = 'text-xl';
      break;
    default:
      subtitleSizeStyles = 'text-lg';
  }

  let textAlignment: string, buttonAlignment: string;
  switch (inlineStyles?.textAlignment) {
    case 'flex-start':
      textAlignment = 'items-start text-start';
      buttonAlignment = 'justify-start';
      break;
    case 'flex-end':
      textAlignment = 'items-end text-end';
      buttonAlignment = 'justify-end';
      break;
    default:
      textAlignment = 'items-center text-center';
      buttonAlignment = 'justify-center';
  }

  const mobileCropStyles = [
    mobileCroppingAutoPosition,
    mobileCroppingCropHeight,
    mobileCroppingCropWidth,
    mobileCroppingXPosition,
    mobileCroppingXPositionAdvanced,
    mobileCroppingYPosition,
    mobileCroppingYPositionAdvanced,
  ];
  const showMobileCropping = !isTabletSize && mobileCropStyles.some((s) => s);

  // Aspect ratio classes are used unless a masthead height setting is applied
  let aspectRatio =
    !inlineStyles?.mastheadSpacer && componentInstance == 0
      ? 'aspect-[9/11] sm:aspect-[16/12] md:aspect-[16/10] lg:aspect-[16/12] xl:aspect-[16/11] 2xl:aspect-auto 2xl:h-[90vh] 2xl:max-h-[1400px]'
      : 'aspect-square sm:aspect-video 2xl:aspect-auto 2xl:h-[75vh] 2xl:max-h-[1200px]';

  const mastheadHeightOptions = ['Tall', 'Short', 'Full'];

  if (mastheadHeightOptions.indexOf(inlineStyles?.mastheadHeight) !== -1) {
    switch (inlineStyles?.mastheadHeight) {
      case 'Tall':
        aspectRatio =
          'h-[60vh] min-h-[450px] sm:h-[85vh] sm:max-h-[55vh] sm:max-h-[85vh]';
        break;
      case 'Short':
        aspectRatio = 'h-[60vh] min-h-[450px]';
        break;
      case 'Full':
        aspectRatio = 'h-[60vh] min-h-[450px] sm:h-screen';
        break;
    }
  }

  const imgUrl = imageUrl(imgSrc);

  const imageTag = imgUrl ? (
    // Output the single hero image
    <div className="cp-heroImageWrapper">
      <CroppedImage
        loader={() => {
          return customLoader({
            src: imgUrl,
            width: 1920,
            height: 1080,
            crop: enableCropping,
            cropType: cropType,
            cropHeight: showMobileCropping
              ? mobileCroppingCropHeight
              : cropHeight,
            cropWidth: showMobileCropping ? mobileCroppingCropWidth : cropWidth,
            xPosition: showMobileCropping ? mobileCroppingXPosition : xPosition,
            yPosition: showMobileCropping ? mobileCroppingYPosition : yPosition,
            xPositionAdvanced: showMobileCropping
              ? mobileCroppingXPositionAdvanced
              : xPositionAdvanced,
            yPositionAdvanced: showMobileCropping
              ? mobileCroppingYPositionAdvanced
              : yPositionAdvanced,
            autoPosition: showMobileCropping
              ? mobileCroppingAutoPosition
              : autoPosition,
            quality: 70,
          });
        }}
        src={imgUrl}
        className={cx('z-[15]', !hideAnimations ? 'animate-fade-in-slow' : '')}
        objectFit="cover"
        objectPosition={
          imgPositioning || inlineStyles?.contentBackgroundPosition
        }
        alt={imgAlt}
        layout="fill"
        priority
      />
    </div>
  ) : null;

  const titleStyle = title && (
    <p
      className={cx('inline-block text-bg font-bold', titleSizeStyles)}
      style={{
        color:
          (!isTabletSize && inlineStyles?.mobileTitleColour) ||
          inlineStyles?.titleColor,
      }}
    >
      {title}
    </p>
  );

  const subtitleStyle = subtitle && (
    <WrappedSubtitle>
      <p
        className={cx('inline-block text-bg', subtitleSizeStyles)}
        style={{
          color:
            (!isTabletSize && inlineStyles?.mobileSubtitleColour) ||
            inlineStyles?.subtitleColor,
        }}
      >
        {subtitle}
      </p>
    </WrappedSubtitle>
  );
  const copyContent = (title || subtitle || buttons?.some((b) => b?.link)) && (
    <div
      id={`overlay-content-${componentInstance}`}
      className={cx('max-[320px]:hidden w-full p-4 lg:p-8 order-2 z-20', {
        'lg:w-auto': !isFullWidthOverlay,
      })}
      style={{
        backgroundColor: bgRGBStyles,
        marginTop: headerDisplacement,
        backdropFilter: `blur(${
          (isTabletSize && inlineStyles?.overlayBlur) || 0
        }px)`,
      }}
    >
      <div
        className={cx(
          'flex flex-col',
          !isFullWidthOverlay ? textAlignment : '',
          {
            'lg:w-auto': !isFullWidthOverlay,
            'max-w-screen-lg mx-auto': isFullWidthOverlay,
          }
        )}
      >
        {title && (
          <div
            className={cx(
              'heroImage-title flex flex-col relative mb-2',
              animations?.one
            )}
          >
            {title && <div>{titleStyle}</div>}
          </div>
        )}
        <div
          className={cx(animations?.three, {
            'flex flex-wrap xl:flex-nowrap items-center': isFullWidthOverlay,
          })}
        >
          {subtitle && (
            <div
              className={cx('heroImage-title', {
                'mr-3': isFullWidthOverlay && buttons,
              })}
            >
              {subtitleStyle}
            </div>
          )}
          {buttons ? (
            <div className={cx('flex relative z-20', buttonAlignment)}>
              {buttons?.map((link, key) => {
                if (!link || !link.link) {
                  return null;
                }
                return (
                  <AnchorLink
                    key={key}
                    url={link.link?.url || ''}
                    title={link.link?.title || ''}
                    target={link.link?.target || ''}
                    buttonStyle={link?.buttonStyle ?? 'primary'}
                    sectionHeading={title}
                    className={`text-nowrap ${key === 0 ? 'ml-0' : ''}`}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  function handleImageChange(isNext?: boolean) {
    const count = imageCarousel ? imageCarousel.length : 0;
    const getNextIndex = () =>
      slideIndex + 1 > count - 1 ? 0 : slideIndex + 1;
    const getPrevIndex = () =>
      slideIndex - 1 < 0 ? count - 1 : slideIndex - 1;
    if (!imageCarousel || imageCarousel.length <= 1) {
      return;
    }
    showImageGallery();
    if (isNext && !navAnimationDir) {
      setNavAnimationDir(true);
    } else if (!isNext && navAnimationDir) setNavAnimationDir(false);
    if (!nextTimeout) {
      const nextInd = isNext ? getNextIndex() : getPrevIndex();
      setNextImage({
        image: imageCarousel[nextInd],
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setSlideIndex(isNext ? getNextIndex() : getPrevIndex());
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      let skipTransInd =
        slideIndex === count - 1
          ? 1
          : slideIndex + 2 > count - 1
          ? 0
          : slideIndex + 2;
      if (!isNext)
        skipTransInd =
          slideIndex === 0
            ? 1
            : slideIndex - 2 < 0
            ? count - 1
            : slideIndex - 2;
      setSlideIndex(skipTransInd);
      setNextImage({
        image: imageCarousel[skipTransInd],
        ind: skipTransInd,
      });
    }
  }

  function next() {
    handleImageChange(true);
  }

  function prev() {
    handleImageChange();
  }

  //after all use*() functions
  if (shouldHideOnRoomsPage)
    return <Spacer componentHeight={defaultPaddingSize}></Spacer>;
  return (
    <div
      data-testid="hero"
      className={cx(
        'cp-heroImage',
        paddingStyles,
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundColor:
          inlineStyles?.mastheadSpacer && inlineStyles?.mastheadSpacerColour
            ? inlineStyles?.mastheadSpacerColour
            : undefined,
        paddingTop:
          inlineStyles?.mastheadSpacer && isLargeDesktopSize
            ? !headerHeight && componentInstance === 0
              ? defaultPaddingSize
              : headerHeight
            : undefined,
        transition: 'padding-top .5s ease',
      }}
    >
      <div className={cx('relative overflow-hidden', aspectRatio)}>
        {/* If Image Gallery exists, output gallery markup */}
        {imageCarouselChoice && imageCarousel && (
          <HeroImageSlider
            images={imageCarousel}
            styleOptions={{
              uiBackgroundColour: inlineStyles?.heroCarouselBackgroundColour,
              uiControlsColour: inlineStyles?.heroCarouselArrowColour,
              heroCarouselCounterColour:
                inlineStyles?.heroCarouselCounterColour,
              imagePositioning:
                imgPositioning || inlineStyles?.contentBackgroundPosition,
              sliderAnimations: inlineStyles?.sliderAnimations,
            }}
            componentInstance={componentInstance}
            hideAnimations={hideAnimations}
            slideIndex={slideIndex}
            navAnimationDir={navAnimationDir}
            nextTimeout={nextTimeout}
            nextImage={nextImage}
            next={next}
            prev={prev}
          />
        )}
        {/* Output the image tag from above */}
        {imageTag}
        {/* Output the markup for masthead gradient */}
        <div
          data-testid="mastheadGradientStyles"
          className={cx(
            'absolute inset-0 h-1/2 opacity-80 z-20 pointer-events-none',
            mastheadGradientStyles
          )}
        ></div>
        {/* Output the markup for the optional hero image logo */}
        {logoSrc && logoSrc !== '' && (
          <HeroImageLogo
            logoSrc={logoSrc}
            logoAlt={logoAlt}
            logoSize={logoSize}
            logoWidth={logoWidth}
            logoHeight={logoHeight}
            logoOverlayColour={logoOverlayColour}
            logoOverlayOpacity={logoOverlayOpacity}
            hasSpacer={inlineStyles?.mastheadSpacer ? true : false}
            isShopFormInHeader={isShopFormInHeader}
            hideAnimations={hideAnimations}
            inlineStyles={inlineStyles}
          />
        )}
        {/* Output the markup for the hero image overlay, which includes title, subtitle, and CTA markup */}
        <HeroImageOverlay
          hideAnimations={hideAnimations}
          videoAutoplay={videoAutoplay}
          videoCtaPlay={videoCtaPlay}
          videoCtaPause={videoCtaPause}
          videoChoice={videoChoice}
          videoMute={videoMute}
          setVideoMute={setVideoMute}
          videoPause={videoPause}
          setVideoPause={setVideoPause}
          videoActive={videoActive}
          setVideoActive={setVideoActive}
          vidRef={vidRef}
          videoMuteChoice={videoMuteChoice}
          videoUrl={videoUrl}
          videoQualityOverride={videoQualityOverride}
          videoQualitySlider={videoQualitySlider}
          animations={animations}
          imageCarousel={imageCarouselChoice && imageCarousel}
          slideIndex={nextImage?.ind}
          copyContent={isTabletSize && copyContent}
          isFullWidthOverlay={isFullWidthOverlay}
          isTabletSize={isTabletSize && !isDesktopSize}
          next={next}
          prev={prev}
          inlineStyles={inlineStyles}
        />
      </div>
      {!isTabletSize && copyContent}
      {/* Output the markup for the booking widget */}
      {bookingWidgetChoice && (
        <div
          className={cx(
            bookingWidgetPosition === 'Overlapping' ? 'md:h-auto' : '',
            bookingWidgetPosition === 'Bottom' && hasBothControls
              ? '2xl:h-0'
              : bookingWidgetPosition === 'Bottom'
              ? 'xl:h-0'
              : '',
            bookingWidgetMobile ? 'flex' : 'hidden md:flex',
            isPropertyGroup && 'hidden md:hidden lg:flex',
            'justify-center',
            {
              'animate-fade-in-up': !hideAnimations,
            }
          )}
        >
          <div
            data-element-id="osc-composable-hero-div-wrapper"
            data-testid="booking-widget-div-outer"
            className={cx(
              bookingWidgetPosition === 'Bottom' && hasBothControls
                ? '2xl:-translate-y-full'
                : bookingWidgetPosition === 'Bottom'
                ? 'xl:-translate-y-full'
                : '',
              bookingWidgetPosition === 'Overlapping' && !isFullWidthOverlay
                ? '2xl:-translate-y-1/2 2xl:shadow-lg'
                : bookingWidgetPosition === 'Overlapping'
                ? 'xl:-translate-y-1/2 xl:shadow-lg'
                : '',
              bookingWidgetMobile
                ? 'inline-block w-full md:w-auto'
                : 'hidden md:inline-block',
              'lg:h-28 p-3 lg:px-4 lg:py-8 transform bg-bg relative z-20',
              associatedHotels?.length > 0 ? 'lg:h-32 lg:py-5' : ''
            )}
          >
            <OscComposableSearchForm
              ctyhocn={bookingWidgetConfig?.ctyhocn}
              defaultArrivalDate={bookingWidgetConfig?.defaultArrivalDate}
              gmtHours={bookingWidgetConfig?.gmtHours}
              brandCode={bookingWidgetConfig?.brandCode}
              currency={bookingWidgetConfig?.currency}
              associatedHotels={associatedHotels}
              oscBookButtonStyle={
                bookingWidgetConfig?.brandCode === 'WA'
                  ? ''
                  : oscBookButtonStyle
              }
              maxRooms={bookingWidgetConfig?.maxRooms}
              groupBooking={bookingWidgetConfig?.groupBooking}
            />
          </div>
        </div>
      )}
    </div>
  );
}
