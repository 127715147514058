import cx from 'classnames';
import { HeroVideo, HeroVideoControls } from './video';
import { HeroImageOverlayProps } from './hero-image.types';
import { ArrowRight, ArrowLeft } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';

export function HeroImageOverlay({
  hideAnimations,
  animations,
  videoAutoplay,
  videoCtaPlay,
  videoCtaPause,
  videoPause,
  setVideoPause,
  videoActive,
  setVideoActive,
  videoMute,
  setVideoMute,
  videoUrl,
  vidRef,
  videoMuteChoice,
  videoQualityOverride,
  videoQualitySlider,
  videoChoice,
  imageCarousel,
  slideIndex,
  next,
  prev,
  copyContent,
  isFullWidthOverlay,
  isTabletSize,
  inlineStyles,
}: HeroImageOverlayProps) {
  const { t } = useTranslation();
  const areControlsPlacedRight =
    inlineStyles?.videoMastheadCtaPosition === 'right';
  const videoControlsPosition =
    isFullWidthOverlay || isTabletSize
      ? ''
      : areControlsPlacedRight
      ? 'right-2.5 2xl:right-0'
      : 'left-1 2xl:left-0';

  return (
    <div className="h-full">
      <div
        ref={animations?.ref}
        data-testid="contentOverlay"
        className={cx(
          'relative leading-none font-headline h-full w-full flex flex-col flex-wrap justify-end items-center',
          {
            'lg:justify-center z-20 max-w-screen-2xl mx-auto':
              !isFullWidthOverlay && !isTabletSize,
          }
        )}
      >
        {copyContent}
        {(videoChoice || imageCarousel?.length) && (
          <div
            className={cx('z-20 w-full mx-auto px-2.5 lg:px-0 flex', {
              'justify-end':
                areControlsPlacedRight && (isFullWidthOverlay || isTabletSize),
              'max-w-screen-lg': isFullWidthOverlay || isTabletSize,
            })}
          >
            <div
              className={cx(
                'flex items-center',
                videoControlsPosition,
                animations?.three,
                {
                  'absolute bottom-2.5 md:bottom-5 px-2.5':
                    !isFullWidthOverlay && !isTabletSize,
                  'mb-2.5 md:mb-5': isFullWidthOverlay || isTabletSize,
                }
              )}
            >
              {imageCarousel?.length && (
                <div
                  className={cx(
                    'text-text text-base flex justify-center items-center h-12',
                    inlineStyles?.videoCtaShadow || 'shadow-xl',
                    inlineStyles?.heroCarouselRoundedCorners ||
                      inlineStyles?.videoCtaRoundedCorners ||
                      'rounded-sm'
                  )}
                  style={{
                    backgroundColor:
                      inlineStyles?.heroCarouselBackgroundColour ?? '#F2F2F2',
                  }}
                >
                  <button
                    aria-label={t('goToPrevious')}
                    onClick={prev}
                    className="flex items-center justify-center w-full h-full px-4"
                  >
                    <ArrowLeft
                      className="h-4 w-4 mr-0.5"
                      fillColor={inlineStyles?.heroCarouselArrowColour}
                    ></ArrowLeft>
                  </button>
                  <button
                    data-testid="heroSliderNext"
                    aria-label={t('goToNext')}
                    onClick={next}
                    className="flex items-center justify-center w-full h-full px-4 border-x-2"
                    style={{
                      borderColor:
                        inlineStyles?.heroCarouselBorderColour ||
                        inlineStyles?.videoCtaBorderColor,
                    }}
                  >
                    <ArrowRight
                      className="h-4 w-4 ml-0.5"
                      fillColor={inlineStyles?.heroCarouselArrowColour}
                    ></ArrowRight>
                  </button>
                  <div
                    className="flex items-center justify-center w-full h-full px-3 min-w-[52px]"
                    style={{ color: inlineStyles?.heroCarouselArrowColour }}
                  >
                    {`${slideIndex + 1}/${imageCarousel.length}`}
                  </div>
                  <span
                    data-testid="heroSliderPosition"
                    aria-live="polite"
                    className="sr-only"
                  >{`${slideIndex + 1} ${t('of')} ${
                    imageCarousel.length
                  }`}</span>
                </div>
              )}
              {videoChoice && (
                <HeroVideoControls
                  inlineStyles={inlineStyles}
                  videoMuteChoice={videoMuteChoice}
                  videoMute={videoMute}
                  setVideoMute={setVideoMute}
                  vidRef={vidRef}
                  videoPause={videoPause}
                  videoCtaPause={videoCtaPause}
                  videoCtaPlay={videoCtaPlay}
                  videoActive={videoActive}
                  setVideoActive={setVideoActive}
                  setVideoPause={setVideoPause}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {videoChoice && videoActive && (
        <HeroVideo
          videoUrl={videoUrl}
          videoQualityOverride={videoQualityOverride}
          videoQualitySlider={videoQualitySlider}
          vidRef={vidRef}
          videoAutoplay={videoAutoplay}
          hideAnimations={hideAnimations}
        />
      )}
    </div>
  );
}
