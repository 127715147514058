import React, { useEffect } from 'react';
/**
 * These are styling selectors for the OSC Shop Form and the Rooms pages booking widgets
 * The global button style option as seen in the OSC Shop Form is rendered through the Button Styles component.
 * */

interface ShopFormAndBookProps {
  buttons?: Array<{
    backgroundColour?: string;
    backgroundColourHover?: string;
    borderColour?: string;
    borderColourHover?: string;
    borderRadius?: string;
    buttonType?: string;
    fieldGroupName?: string;
    fontFamily?: string;
    hoverStyle?: string;
    textColour?: string;
    textColourHover?: string;
    textTransform?: string;
    buttonHeight?: string;
    buttonPadding?: string;
    fontSize?: string;
    fontWeight?: string;
    pageRestrictions?: string;
    lineHeight?: string;
  }>;
  waldorfButtons?: Array<{
    buttonType?: string;
    textColour?: string;
    textColourHover?: string;
    underlineColour?: string;
    underlineColourHover?: string;
    pageRestrictions?: string;
  }>;
  shopFormAndBookStyles: {
    dateNumberAndRoomIconColor?: string | null;
    mainTextColor?: string | null;
    oscBookButtonBackgroundColor?: string | null;
    oscBookButtonBackgroundColorOnHover?: string | null;
    oscBookButtonBorderColor?: string | null;
    oscBookButtonBorderColorOnHover?: string | null;
    oscBookButtonTextColor?: string | null;
    oscBookButtonTextColorOnHover?: string | null;
    searchAndFilterIcons?: string | null;
    searchButtonBackgroundColor?: string | null;
    searchButtonBackgroundColorOnHover?: string | null;
    searchButtonBorderColor?: string | null;
    searchButtonBorderColorOnHover?: string | null;
    searchButtonTextColor?: string | null;
    searchButtonTextColorOnHover?: string | null;
    selectedBookDatePickerDateBackground?: string | null;
    selectedBookDatePickerDateText?: string | null;
    selectedBookPickerDateRangeBackground?: string | null;
    selectedBookPickerDateRangeText?: string | null;
    shopFormBgColor?: string | null;
    bookButtonStyleFromGlobal: {
      shopFormBookGlobalButtonStyleButtonStyle?: string | null;
    };
    searchButtonStyleFromGlobal: {
      globalButtonStyleSettingButtonStyle?: string | null;
    };
  };
  pageSlug?: string;
}

const SHOP_FORM_PREFIX = '.osc-search-content-wrapper button[type="submit"]';

// OSC Shop Form Global Button Styling

/**
 * Produces class selectors associated with OSC Shop Form styling
 * // String: Outputs selector types based on supplied value: 'search' or 'book'
 * @param bookOrSearch
 * // Boolean: Outputs :focus,:hover state selectors if true
 * @param hasPeudoClasses
 * // String: used for outputting correct background settings on the OSC Book Button
 * @param globalSetting
 */
function oscSelectorHandler(bookOrSearch, hasPeudoClasses, globalSetting = '') {
  const searchArray = [
    'div[data-osc-product="search-rates-button"] > button',
    'div[data-osc-product="search-rooms-button"] > button',
  ];

  // OSC Book array is just one value
  const bookArray = [
    `${SHOP_FORM_PREFIX}${
      bookOrSearch === 'book' && globalSetting
        ? `.btn-${globalSetting}`
        : '.btn-primary'
    }`,
  ];

  const pseudoClassArray = [':hover', ':focus'];

  const selectorArray = bookOrSearch === 'search' ? searchArray : bookArray;

  const combinedSelectorArray = [];

  let string = '';
  // Update result array based on settings
  if (hasPeudoClasses) {
    pseudoClassArray.forEach((pseudoClass) => {
      selectorArray.forEach((selector) => {
        combinedSelectorArray.push(`${selector}${pseudoClass}`);
      });
    });
    string = combinedSelectorArray.join(',');
  } else {
    if (!hasPeudoClasses) {
      string = selectorArray.join(',');
    }
  }

  return string;
}

/**
 * Book button or search buttons in OSC Shop Form
 * @param selectorType
 * Style settings for global button setting type
 * @param globalBtn
 */
function styleOscButtonByType(selectorType, globalBtn) {
  let styles = '';

  // Default states
  styles += `${oscSelectorHandler(selectorType, false)} {`;

  // Button height
  if (globalBtn?.buttonHeight)
    styles += `min-height: ${globalBtn?.buttonHeight};`;

  // Line height
  if (globalBtn?.lineHeight) styles += `line-height: ${globalBtn?.lineHeight};`;

  // Border radius
  if (globalBtn?.borderRadius)
    styles += `border-radius: ${globalBtn?.borderRadius};`;

  // Text transform
  if (globalBtn?.textTransform)
    styles += `text-transform: ${globalBtn?.textTransform};`;

  // Button padding
  if (globalBtn?.buttonPadding)
    styles += `padding-left: ${globalBtn?.buttonPadding};padding-right: ${globalBtn?.buttonPadding};`;

  // Font weight
  if (globalBtn?.fontWeight) styles += `font-weight: ${globalBtn?.fontWeight};`;

  // Font size
  if (globalBtn?.fontSize) styles += `font-size: ${globalBtn?.fontSize};`;

  // Font family
  if (globalBtn?.fontFamily) styles += `font-family: ${globalBtn?.fontFamily};`;

  // Text Color (default state only)
  if (globalBtn?.textColour) styles += `color: ${globalBtn?.textColour};`;

  // Border Color (default state only)
  if (globalBtn?.borderColour)
    styles += `border-color: ${globalBtn?.borderColour};`;

  styles += `}`;

  // Preserve the default numerals inside the Special Rates button badge icon (.875rem)
  styles += `div[data-osc-product="search-rates-button"] > button > div.rounded-full {
    font-size:0.875rem !important;
  }`;

  // Hover/focus states for text and border colors
  styles += `${oscSelectorHandler(selectorType, true)} {`;
  if (globalBtn?.textColourHover)
    styles += `color: ${globalBtn?.textColourHover};`;
  if (globalBtn?.borderColourHover)
    styles += `border-color: ${globalBtn?.borderColourHover};`;
  styles += `}`;

  // Background color (both states and button types)

  // Search
  // Default state
  if (selectorType === 'search') {
    if (globalBtn?.backgroundColour) {
      styles += `${oscSelectorHandler(selectorType, false)} {
        background-color: ${globalBtn?.backgroundColour};
      }`;
    }
    // Hover/focus state
    if (globalBtn?.backgroundColourHover) {
      styles += `${oscSelectorHandler(selectorType, true)} {
        background-color: ${globalBtn?.backgroundColourHover};
      }`;
    }
  }

  // Book
  if (selectorType === 'book') {
    // Default state
    if (globalBtn?.backgroundColour) {
      styles += `
      ${oscSelectorHandler(selectorType, false)},
      ${oscSelectorHandler(selectorType, false)}:after {
        background-color: ${globalBtn?.backgroundColour};
      }
      `;
    }

    // Hover/focus
    if (globalBtn?.backgroundColour) {
      styles += `
      ${oscSelectorHandler(selectorType, false)}:hover,
      ${oscSelectorHandler(selectorType, false)}:hover:after,
      ${oscSelectorHandler(selectorType, false)}:focus,
      ${oscSelectorHandler(selectorType, false)}:focus:after {
        background-color: ${globalBtn?.backgroundColourHover};
      }
      `;
    }
  }

  return styles;
}

export function ShopFormAndBookStyles({
  buttons,
  waldorfButtons,
  shopFormAndBookStyles,
}: ShopFormAndBookProps) {
  const props = shopFormAndBookStyles;
  // Custom button settings in lieu of a global button selection.
  const searchBtnCustomStyle = {
    bgColor: props?.searchButtonBackgroundColor,
    bgColorOnHover: props?.searchButtonBackgroundColorOnHover,
    borderColor: props?.searchButtonBorderColor,
    borderColorOnHover: props?.searchButtonBorderColorOnHover,
    textColor: props?.searchButtonTextColor,
    textColorOnHover: props?.searchButtonTextColorOnHover,
  };

  const oscBookBtnCustomStyle = {
    bgColor: props?.oscBookButtonBackgroundColor,
    bgColorOnHover: props?.oscBookButtonBackgroundColorOnHover,
    borderColor: props?.oscBookButtonBorderColor,
    borderColorOnHover: props?.oscBookButtonBorderColorOnHover,
    textColor: props?.oscBookButtonTextColor,
    textColorOnHover: props?.oscBookButtonTextColorOnHover,
  };

  let styleString = '';

  function matchButtonStyle(buttonTypeFromProps) {
    return buttons?.find(
      (buttonStyle) => buttonStyle?.buttonType === buttonTypeFromProps
    );
  }

  /**
   * Override Global style text color, border color and background color on interactive states
   * selectorType is 'search' or 'book'
   * @param selectorType
   * The style overrides set on the individual Shop Form theme settings panels (not the global button styles)
   * @param styleArray
   * @returns
   */
  function overrideGlobalStyle(selectorType, styleArray, globalSetting = '') {
    let styles = '';
    // Global setting is included to force an overwrite on the OSC Shop Form's book button

    if (selectorType) {
      // Default state -- Background color override for the OSC Book Button handled separately
      styles += `
      ${oscSelectorHandler(selectorType, false, globalSetting)}${
        globalSetting ? `.btn-${globalSetting}` : ''
      } `;

      styles += `{`;

      if (styleArray?.borderColor)
        styles += `border-color: ${styleArray?.borderColor} !important;`;

      if (styleArray?.textColor)
        styles += `color: ${styleArray?.textColor} !important;`;

      // Search buttons only
      if (styleArray?.bgColor && selectorType === 'search')
        styles += `background-color: ${styleArray?.bgColor} !important; background: ${styleArray?.bgColor} !important; `;

      styles += `}`;

      // Book button in modal
      if (selectorType === 'book') {
        styles += `${SHOP_FORM_PREFIX} {`;
        if (styleArray?.borderColor)
          styles += `border-color: ${styleArray?.borderColor} !important;`;

        if (styleArray?.textColor)
          styles += `color: ${styleArray?.textColor} !important;`;
        styles += `}`;
      }

      // Hover/focus state -- Background color hover override for OSC Shop Form's book button handled separately.

      styles += `${oscSelectorHandler(selectorType, true, globalSetting)}${
        globalSetting ? `.btn-${globalSetting}` : ''
      } {`;

      if (styleArray?.borderColorOnHover)
        styles += `border-color: ${styleArray?.borderColorOnHover} !important;`;

      if (styleArray?.textColorOnHover)
        styles += `color: ${styleArray?.textColorOnHover} !important;`;
      // Search buttons only
      if (styleArray?.bgColorOnHover && selectorType === 'search')
        styles += `background-color: ${styleArray?.bgColorOnHover} !important;background: ${styleArray?.bgColorOnHover} !important; `;

      styles += `}`;

      // Book button in modal hover/focus state
      if (selectorType === 'book') {
        styles += `${SHOP_FORM_PREFIX}:hover,${SHOP_FORM_PREFIX}:focus  {`;
        if (styleArray?.borderColor)
          styles += `border-color: ${styleArray?.borderColorOnHover} !important;`;

        if (styleArray?.textColor)
          styles += `color: ${styleArray?.textColorOnHover} !important;`;
        styles += `}`;
      }

      // Book button background override handling. If no global setting type is provided, the default is primary
      if (
        selectorType === 'book' &&
        (styleArray?.bgColor || styleArray?.bgColorOnHover)
      ) {
        const globalToUse = !globalSetting ? 'primary' : globalSetting;

        // Determine whether button global setting has animation or not

        const appliedBookGlobalStyle = buttons?.find(
          (el) => el?.buttonType === globalToUse
        );

        const animationType = appliedBookGlobalStyle?.hoverStyle || 'default';

        if (styleArray?.bgColor) {
          const oscBgDefault = `
          [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX},
          body > ${SHOP_FORM_PREFIX}.btn-${globalToUse},
          ${SHOP_FORM_PREFIX}.btn-${globalToUse} {
            background-color: ${styleArray?.bgColor} !important;
            background: ${styleArray?.bgColor} !important;
        }`;

          styles += oscBgDefault;
        }

        // Hover/focus
        if (styleArray?.bgColorOnHover) {
          let oscBgInteract = '';

          if (animationType === 'default') {
            oscBgInteract += `
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:hover,
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:focus,
          ${SHOP_FORM_PREFIX}.btn-${globalToUse}:hover,
          ${SHOP_FORM_PREFIX}.btn-${globalToUse}:focus
          `;
          } else {
            if (animationType !== 'default') {
              oscBgInteract += `
        
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:hover:after,
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:focus:after,
            ${SHOP_FORM_PREFIX}.btn-${globalToUse}:hover:after,
            ${SHOP_FORM_PREFIX}.btn-${globalToUse}:focus:after
            `;
            }
          }

          oscBgInteract += `{
          background-color: ${styleArray?.bgColorOnHover} !important;
          background: ${styleArray?.bgColorOnHover} !important;}`;

          styles += oscBgInteract;
        }
      }
    }

    return styles;
  }

  // Room Search and Special Rates Buttons for properties other than Waldorf Astoria

  // Use the global button style, if selected, and then add any custom overrides
  if (
    shopFormAndBookStyles?.searchButtonStyleFromGlobal
      ?.globalButtonStyleSettingButtonStyle
  ) {
    // Check for a possible match among any available global site button styles and the OSC button setting.
    const globalBtnMatch = matchButtonStyle(
      shopFormAndBookStyles?.searchButtonStyleFromGlobal
        ?.globalButtonStyleSettingButtonStyle
    );

    if (globalBtnMatch && globalBtnMatch !== undefined)
      styleString += styleOscButtonByType('search', globalBtnMatch);
  }

  // OSC Book Button
  if (
    shopFormAndBookStyles?.bookButtonStyleFromGlobal
      ?.shopFormBookGlobalButtonStyleButtonStyle
  ) {
    // Check for a possible match among any available global site button styles and the OSC button setting.
    const globalBtnMatch = matchButtonStyle(
      shopFormAndBookStyles?.bookButtonStyleFromGlobal
        ?.shopFormBookGlobalButtonStyleButtonStyle
    );

    if (globalBtnMatch && globalBtnMatch !== undefined)
      styleString += styleOscButtonByType('book', globalBtnMatch);
  }

  // Apply Shop Form Button Specific CMS settings
  let hasCustomSearchBtnSettings = false;

  Object.values(searchBtnCustomStyle).forEach((setting) => {
    if (setting && !hasCustomSearchBtnSettings) {
      hasCustomSearchBtnSettings = true;
      return false;
    }
  });

  // If here are custom button settings, those should override the global settings
  if (hasCustomSearchBtnSettings) {
    styleString += overrideGlobalStyle('search', searchBtnCustomStyle);
  }

  // Booking button in OSC Shop Form (separate settings from the Book/Make A Reservation button in the upper-right part of the header)
  // Book button appears in two possible places
  let bookButtonHasStyling = false;
  if (oscBookBtnCustomStyle) {
    Object.values(oscBookBtnCustomStyle).forEach((el) => {
      if (el) {
        bookButtonHasStyling = true;
        return false;
      }
    });
  }

  if (bookButtonHasStyling) {
    styleString += overrideGlobalStyle(
      'book',
      oscBookBtnCustomStyle,
      shopFormAndBookStyles?.bookButtonStyleFromGlobal
        ?.shopFormBookGlobalButtonStyleButtonStyle
    );
  }

  if (props?.searchAndFilterIcons) {
    styleString += `
      [data-osc-product='search-dates'] > div  > div:first-of-type > button svg path,
      [data-reach-dialog-content] > div > h2 + button svg path,
      .cp-calendar-monthArrows:not(:disabled) svg path  { 
        stroke: ${props?.searchAndFilterIcons} !important;
      }
    `;
  }

  // Large date numbers, Plus Icons (+) in OSC room selection dialog and the sub-link in same panel ("Book 10 or more rooms...")
  if (props?.dateNumberAndRoomIconColor) {
    styleString += `
  .rooms-widget-wrapper > button > div > span.font-bold,  
  [data-osc-product="shop-form-dates"] > div > span.text-primary {
    
      color: ${props?.dateNumberAndRoomIconColor};
    }

    [data-osc-product="rooms"] button:not(:disabled) svg,
    [data-osc-product="rooms"] + div button svg {
      path: ${props?.dateNumberAndRoomIconColor};
      stroke: ${props?.dateNumberAndRoomIconColor};
    }
    `;
  }

  // Main text – most labels and non-selected, non-expired date numbers in calendar date pickers
  if (props?.mainTextColor) {
    styleString += `
      [data-osc-product="shop-form-dates"] > div > span + div span,
      .rooms-widget-wrapper > button > div > div span.tabular-nums,
      [data-reach-dialog-content] > div > h2 ,
      [data-reach-dialog-content] label span,
      [data-reach-dialog-content] div > div > div > span,
      [data-osc-product="rooms"] + div button,
      [data-osc-product="search-dates"] h2,
      [data-osc-product="search-dates"] table thead,
      [data-osc-product="search-dates"] table tbody td button:not(:disabled,.text-text-inverse) span,
      [data-osc-product="shop-form-dates"] > div > div > span,
      span[id^="calendar-month"],
      span[id^="calendar-month"] + table  {
        color: ${props?.mainTextColor};
      }
    `;
  }

  // Calendar datepicker styling for table cells
  if (
    props?.selectedBookDatePickerDateText ||
    props?.selectedBookDatePickerDateBackground
  ) {
    styleString += `
      [data-osc-product="search-dates"] table tbody td button.text-text-inverse,
      span[id^="calendar-month"] + table tbody td button.bg-primary {
        ${
          props?.selectedBookDatePickerDateText
            ? `color: ${props?.selectedBookDatePickerDateText};`
            : ''
        }
        ${
          props?.selectedBookDatePickerDateBackground
            ? `background: ${props?.selectedBookDatePickerDateBackground};`
            : ''
        }
      }
    `;
  }

  // Date ranges (anything in between two selected dates)
  if (
    props?.selectedBookPickerDateRangeBackground ||
    props?.selectedBookPickerDateRangeText
  ) {
    styleString += `
      [data-osc-product="search-dates"] table tbody td button.text-text,
      span[id^="calendar-month"] + table tbody td button.bg-secondary {
        ${
          props?.selectedBookPickerDateRangeBackground
            ? `background: ${props?.selectedBookPickerDateRangeBackground};`
            : ''
        }    
      }
      [data-osc-product="search-dates"] table tbody td button.text-text span,
      span[id^="calendar-month"] + table tbody td button.bg-secondary span,
      span[id^="calendar-month"] + table tbody td button.bg-secondary {
        ${
          props?.selectedBookPickerDateRangeText
            ? `color: ${props?.selectedBookPickerDateRangeText};`
            : 'color:#ffffff;'
        }
      }
    `;
  }

  if (props?.shopFormBgColor) {
    styleString += `
    div.generic-modal-booking-widget,
    [data-element-id="osc-composable-hero-div-wrapper"] {
      background: ${props?.shopFormBgColor} !important;
    }
    `;
  }

  return (
    <style
      data-style-type="osc-shop-form"
      data-testid="shop-form-style-tag"
      suppressHydrationWarning
    >
      {styleString}
    </style>
  );
}
